.username{
  flex-direction: row;
  align-items: center;
  max-width: 100%;
}
.username-text{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: inherit;
  font:inherit;
  line-height: inherit;
  display: inline;
  flex-shrink: 1;
}

.username-group{
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  > div {
    display: inline;
  }
}