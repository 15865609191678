.toast{
  background-color: #262626;
  border-radius: 8px;
  opacity: 0.9;
  padding: 16px 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition:opacity 250ms ease-out;
  transform-origin: center center;
}
.toast-content{
  font-size: 14px;
  line-height: 18px;
  margin: -3px 0 -4px;
  font-weight: 400;
  color: #fff;
}
.toast-hide{
  opacity: 0;
}