
.user-tag{
  display: block;
  position: absolute;
  touch-action: none;
  user-select: none;
  z-index: 10;
}
.user-tag-content{
  background-color: rgba(0,0,0,.85);
  border-radius: 4px;
  display: block;
}

.tag-content-btn{
  padding:0;
  align-items: center;
  flex-direction: row;
}

.content-btn-arrow{
  border-style: solid;
  height: 0;
  left: 50%;
  margin-left: -6px;
  position: absolute;
  width: 0;
}

.btn-arrow-bottom{
  border-color: rgba(0,0,0,.85) transparent transparent;
  border-width: 6px 6px 0;
  top: 100%
}


.btn-arrow-top{
  border-color: transparent transparent rgba(0,0,0,.85);
  border-width: 0 6px 6px;
  top: -5px
}

.content-btn-username{
  margin: 0 12px;
  color: #fff;
  line-height: 36px;
  font-weight: 600;
}