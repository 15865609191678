.adaptive-container{
  height: 100%;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.adaptive-container-content{
  background:#fff;
  width:548px;
  margin: 20px;
  max-height: calc(100vh - 40px);
  border-radius: 12px;
  overflow: hidden;
  pointer-events: auto;
  @media (max-width: 735px){
    max-height: 100%;
    width: 100%;
    flex-grow: 1;
    margin:0;
    align-self: stretch;
    border-radius: 0;
  }
}