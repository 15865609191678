.user-cell{
  min-width: 0;
}
.user-cell-column{
  justify-content: center;
}
.user-cell-row,.user-cell-column{
  align-items: center;
}

.user-cell-row{
  flex:1;
  .user-cell-description{
    flex: 1;
  }
}

.user-cell-description{
  font-size: 12px;
  overflow: hidden;
}
.cell-description-text{
  max-width: 100%;
  font-size: 12px;
  display: block;
  line-height: 14px;
  color: #8e8e8e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.description-text-low{
  font-size: 10px;
}
.cell-description-column{
  width:100%;
  align-items: center;
}
.user-cell-username{
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}