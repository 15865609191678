.policy-education{
  padding:16px;
}
.policy-education-title{
  font-weight: 600;
  font-size:16px;
  line-height: 24px;
}
.policy-education-content{
  margin-top:12px;
}
.content-first-subtitle{
  font-weight: 600;
  color:#8e8e8e;
}
.content-second-subtitle{
  color:#8e8e8e;
  margin-bottom:10px;
}
.education-content-item{
  flex-direction: row;
  justify-content: flex-start;
  color:#8e8e8e;
  margin-top:4px;
}
.content-item-dot{
  margin-right: 8px;
  color:#8e8e8e;
}
.content-item-text{
  flex: 1;
  color:#8e8e8e;
}
