.bubble{
  flex:0 0 auto;
  max-width: 236px;
}

.FEED,.TEXT,.IMG,.USER{
  border-radius: 22px;
  overflow: hidden;
}

.EMOJI{
  font-size:50px;
  line-height: 1;
}

.TEXT,.USER{
  padding:16px;
}

.TEXT{
  min-height: 44px;
  margin:-3px 0 -4px;
}

.bubble-text{
  overflow-wrap: break-word;
  white-space: normal;
  font-weight: 400;
  font-size: inherit;
  line-height: inherit;
}
.bubble-liked-users{
  margin-bottom:20px;
}
.none-liked-users{
  margin-bottom: 8px;
}