.change-avatar-title{
  margin:32px 32px 16px 32px;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
}
.change-avatar{
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  z-index: 100;
  background:#ffffff;
}