.TV{
  flex:1;
  background-color:#000;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  bottom:0;
}
.TV-title{
  font-weight: 600;
  color:#fff;
  font-size: 18px;
  padding-right:48px;
}
.TV-video{
  flex:1;
}

.TV-video-content{
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  justify-content: center;
  video{
    display: block;
    height: unset;
    max-height: 100%;
    width:100%;
    object-fit: cover;
  }
}

.TV-sheet{
  padding:16px;
}

.TV-sheet-title{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.TV-sheet-preview{
  font-size: 14px;
  line-height: 18px;
  margin: -3px 0 -4px;
  color: #fff;
}