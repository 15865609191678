.option-button{
  height:44px;
  border-top: 1px solid #dbdbdb;
  background:#fff;
  padding:0 16px;
  justify-content: space-between;
}
.options-button-text{
  text-align: left;
  flex:1;
  font:inherit;
  font-size:16px;
  font-weight: 400;
  a{
    font:inherit;
    color:inherit;
  }
}
.button-text-WARNING{
  color:#ed4956;
}