.post-header{
  padding:16px 4px 16px 16px;
  height:60px;
  align-items: center;
  border-bottom: 1px solid #efefef;
  flex-direction: row;
}
.post-header-options{
  position: relative;
  padding:12px;
  align-items: center;
  justify-content: center;
}