.modal-container{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:100;
  background-color: rgba(0,0,0,.65);
}
.modal-container-mask{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height: 100%;
}