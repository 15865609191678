.suggested{
  border: 1px solid #dbdbdb;
  border-left: none;
  border-right: none;
  @media (min-width: 640px){ 
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    margin-bottom: 60px;
  }
}

