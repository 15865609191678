
.form-item-label{
  display:flex;
}
.item-label-text{
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 6px;
  margin-bottom:6px;
}

.label-input-help{
  color: #8e8e8e;
  font-size: 12px;
  line-height: 14px;
  margin-top:16px;
}

@media (max-width: 735px) {
  .form-item{
    padding: 0 20px;
    margin-bottom:8px;
  }
  .form-item-label{
    flex-direction: column;
  }
}

@media (min-width:736px) {
  .form-item{
    margin-bottom:16px;
  }
  .item-label-input{
    width: 355px;
  }
  .form-item-label{
    flex-direction: row;
  }
  .item-label-text{
    padding-left: 32px;
    padding-right: 32px;
    text-align: right;
    flex: 0 0 194px;
  }
}