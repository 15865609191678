.radio{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.radio-input{
  appearance: none;
  background-color:#fff;
  transition: .15s all linear;
  border: 2px solid #dbdbdb;
  border-radius: 50%;
  margin:3px 8px 0px 5px;
}

.radio-input-LARGE{
  width:24px;
  height:24px;
  &:checked{
    border: 7px solid #0095f6;
  }
}

.radio-input-SMALL{
  width:18px;
  height:18px;
  &:checked{
    border: 5px solid #0095f6;
  }
}

.radio-input-MINI{
  width:14px;
  height:14px;
  &:checked{
    border: 4px solid #0095f6;
  }
}