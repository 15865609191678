
.retirer-follow-confirm{
  margin: 16px 32px;
  text-align: center;
  font-weight: 400;
}
.follow-confirm-text{
  margin-top:14px;
  color: #8e8e8e;
  font-weight: 400;
}