.app-navigation{
  flex-direction: row;
  flex:1;
}
.app-navigation-menu{
  position: relative;
  flex:1;
  justify-content: center;
  align-items: center;
  height:100%;
  a{
    justify-content: center;
    align-items: center;
  }
}
.navigation-menu-notification{
  position: relative;
  &::after{
    background: #ed4956;
    border-radius: 2px;
    bottom: 5px;
    content: '';
    height: 4px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    -webkit-transform: translateX(-.5px);
    transform: translateX(-.5px);
    width: 4px;
  }
}
.menu-avatar-active{
  position:absolute;
  width:30px;
  height:30px;
  border-radius: 50%;
  border:1px solid #262626;
}