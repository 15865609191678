.select{
  display: inline-flex;
}
.select-content{
  appearance: none;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  color: #8e8e8e;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
  font-size: 12px;
  height: 36px;
  padding: 0 24px 0 8px;
}

.select-icon{
  pointer-events: none;
  position: absolute;
  right: 7px;
  top: 12px;
}