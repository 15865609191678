.checkbox{
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  input{
    border: 0;
    line-height: 18px;
    padding: 0 8px;
    text-overflow: ellipsis;
    left: -9999px;
    position: absolute;
  }
  input:checked~.checkbox-box{
    &:before{
      border-bottom: 2px solid #262626;
      border-left: 2px solid #262626;
      content: ' ';
      display: block;
      height: 3px;
      left: 2px;
      position: absolute;
      top: 3px;
      transform: rotateZ(-45deg);
      width: 8px;
    }
  }
  input:focus~.checkbox-box{
    border-color: #0095f6;
  }
  input:active~.checkbox-box{
    box-shadow: inset 0 0 1px 1px #dbdbdb;
  }
}

.checkbox-disabled{
  opacity: 0.3;
  pointer-events: none;
}

.checkbox-box{
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  display: inline-block;
  height: 16px;
  margin: 0 8px 0 3px;
  position: relative;
  width: 16px;
}