.snack-bar {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 500;
  overflow: hidden;
}

.snack-bar-content {
  top: 0;
  background-color: #262626;
  flex-direction: row;
  min-height: 44px;
  padding: 0 16px;
  width: 100%;
  transition: transform .2s ease-out;
  align-items: center;
  transform: translateY(100%);
}
.bar-content-text {
  flex:1;
  color: #fff;
  line-height: 18px;
  max-height: 72px;
  padding: 12px 0;
  overflow: hidden;
}
.bar-content-visible{
  transform: translateY(0);
}