.spinning{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}
.button{
  padding:0;
  color: #0095f6;
  font-weight: 600;
}