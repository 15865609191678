.confirm-modal{
  padding:32px;
  max-width: 100%;
  flex-direction: column;
}
.confirm-modal-title{
  font-weight: 600;
  font-size:18px;
  line-height: 24px;
  margin: -4px 0 -6px;
}
.confirm-modal-text{
  font-weight: 400;
  margin: -4px 0 -6px;
  color: #8e8e8e;
  padding-top: 16px;
  font-size:14px;
  word-break: break-all;
}