.nav-bar{
  width: 100%;
  box-sizing: border-box;
}

.nav-bar-content{
  flex-direction: row;
  height: 100%;
  width:100%;
  background:#fff;
}

.nav-bar-fixed{
  position:fixed;
  left:0;
  z-index:1;
}

.nav-fixed-TOP{
  top:0;
}

.nav-fixed-BOTTOM{
  bottom:0;
}

.nav-border-TOP{
  &::after{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height: 1px;
    background:#dbdbdb;
  }
}

.nav-border-BOTTOM{
  &::after{
    content:'';
    position:absolute;
    left:0;
    bottom:0;
    width:100%;
    height: 1px;
    background:#dbdbdb;
  }
}

.title{
  display: flex;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  flex:1;
  min-width:0;
}
.navigation{
  align-items: center;
  justify-content: center;
  flex:0 0 48px;
  z-index: 1;
}