.grid-lines{
  border: .5px solid #dbdbdb;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  .grid-lines-thread:nth-child(1){
    left: 33%;
    top: 0%;
    width: 1px;
    height: 100%;    
  }
  .grid-lines-thread:nth-child(2){
    right: 33%;
    top: 0%;
    width: 1px;
    height: 100%;
  }
  .grid-lines-thread:nth-child(3){
    top: 33%;
    left: 0%;
    height: 1px;
    width: 100%;
  }
  .grid-lines-thread:nth-child(4){
    bottom: 33%;
    left: 0%;
    height: 1px;
    width: 100%;
  }
}

.grid-lines-thread{
  background-color: rgba(255,255,255,.3);
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
  position: absolute; 
}