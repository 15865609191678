.post-row-LARGE{
  display:block;
  margin-right: -2px;
  .post-row-item{
    display: block;
    margin: 2px 2px 0 0;
  }
}



.row-item-LARGE{
  width: calc(66.6666% - 2px);
}

.row-item-DEFAULT{
  width: calc(33.3333% - 2px);
}

.row-item-LEFT{
  float:left;
}

.row-item-RIGHT{
  float:right;
}

.post-row{
  flex-direction: row;
  margin-right: -2px;
  .post-row-item{
    margin-right: 2px;
    margin-bottom: 2px;
  }
}


@media (min-width: 736px){
  .post-row{
    margin-right: -28px;
    .post-row-item{
      margin:0 28px 28px 0;
    }
  }
  .post-row-LARGE{
    margin-right: -28px;
    .row-item-LARGE{
      width: calc(66.6666% - 28px);
    }
    .row-item-DEFAULT{
      width: calc(33.3333% - 28px);
    }
    .post-row-item{
      margin:0 28px 28px 0;
    }
  }
}

.post-row-item{
  flex:1;
}

.row-item-container{
  display: block;
  background-color: #efefef;
  width:100%;
}

.item-container-content{
  display:block;
  overflow: hidden;
  padding-bottom:100%;
}

.channel-preview-icon{
  margin-right:4px;
}

.container-content-img{
  height:100%;
  width:100%;
  position:absolute;
  top:0;
  left:0;
  object-fit: cover;
  user-select: none;
}

.container-content-media,.container-content-channel{
  align-items: flex-end;
  position:absolute;
  top:0;
  left:0;
  pointer-events: none;
  bottom:0;
  right:0;
  justify-content: flex-start;
}

.container-content-channel{
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 22.27%);
}

.content-media-icon{
  filter: drop-shadow(0 0 .75px rgba(0,0,0,.42)) drop-shadow(0 1px .5px rgba(0,0,0,.18)) drop-shadow(0 2px 3px rgba(0,0,0,.2));
}

.content-channel-preview{
  position:absolute;
  bottom:8px;
  left:8px;
  flex-direction: row;
  align-items: center;
  font-size:12px;
  color:#ffffff;
  line-height: 16px;
}