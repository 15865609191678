.button{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  text-transform: inherit;
  text-overflow: ellipsis;
  border: 1px solid transparent;
  &:active{
    opacity: 0.7;
  }
  &:disabled{
    opacity: .3;
    pointer-events: none;
  }
}

.button-PRIMARY{
  background-color: #0095f6;
  color: #fff;
}

.button-dangerous{
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f;
}

.button-DEFAULT{
  border: 1px solid #dbdbdb;
}

.button-TEXT{
  border: none;
}

.button-SMALL{
  padding:2px 4px;
}

.button-LARGE{
  padding: 12px 18px;
}

.button-MIDDLE{
  padding: 5px 9px;
}