.dot {
  border-color: #fff;
  border-radius: 50%;
}

.DEFAULT {
  height: 20px;
  width: 20px;
  border-width: 3.5px;
  margin: 3px 0 0 3px
}

.MEDIUM {
  height: 16px;
  width: 16px;
  border-width: 3px;
  margin: 2.3px -2.3px -2.3px 2.3px
}

.SMALL {
  height: 14px;
  width: 14px;
  border-width: 2.5px;
  margin: 2px -2px -2px 2px
}

.TINY {
  height: 12px;
  width: 12px;
  border-width: 2.3px;
  margin: 2px -2px -2px 2px
}

.ATOMIC {
  height: 10px;
  width: 10px;
  border-width: 1.5px;
  margin: 1.5px -1.5px -1.5px 1.5px
}

.SUPERATOMIC{
  width:8px;
  height:8px;
  border:0;
}