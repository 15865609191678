.comments{
  flex: 1 1 auto;
  overflow-y: auto;
}

.comments-caption{
  padding-bottom:16px;
  margin-bottom:16px;
  &::after{
    position:absolute;
    content:'';
    width:calc(100% + 44px);
    height: 1px;
    left:-16px;
    bottom:0;
    background:#efefef;
  }
}

.comments-item{
  margin-top:12px;
  margin-bottom:16px;
}
.comments-item-children{
  margin: 16px 0 0 50px;
  > div{
    margin-top:12px;
  }
}
.comments-more{
  min-height: 40px;
  justify-content: center;
  align-items: center;
}