.filtered-image{
  border-bottom:1px solid #dbdbdb;
  width:100%;
}

.filtered-image-container{
  width:100%;
  padding-bottom:100%;
}

.image-container-content{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.container-content-canvas{
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}