.sticky-modal {
  position: absolute;
  background-color: #fff;
  top:0;
  width:100%;
  height: 150%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  transform: translateY(100%);
}

.sticky-modal-bar {
  padding: 12px;
}

.modal-bar-inner {
  margin: 0 auto;
  height: 4px;
  width: 48px;
  border-radius: 2px;
  background-color: #dbdbdb;
}

.sticky-modal-content {
  overflow: auto;
  transition: height 0.1s ease;
}

.sticky-modal-title {
  display: flex;
  border-bottom: 1px solid #dbdbdb;
  height: 43px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
}