
.report-tags{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 80px);
}
.report-tags-title{
  padding:16px;
  font-weight: 600;
  font-size:16px;
}
.report-tags-content{
  &::before{
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #efefef;
  }
}

button.tags-content-btn{
  height:50px;
  font-weight: 400;
  border-top:none;
  border-bottom: 1px solid #dbdbdb;
  &:active{
    background-color: #efefef;
  }
}

.tags-content-radio{
  position: relative;
  padding:16px;
  flex:1;
  &:active{
    background-color: #efefef;
  }
  &::after{
    position: absolute;
    bottom: 0;
    left:0;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #efefef;
  }
  input{
    margin-right: 12px;
  }

}