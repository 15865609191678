
.remove-follower-confirm{
  margin: 16px 32px;
  text-align: center;
  font-weight: 400;
}

.follower-confirm-title{
  font-size: 22px;
  line-height: 26px;
  font-weight: 300;
}

.follower-confirm-text{
  margin-top:14px;
  color: #8e8e8e;
  font-weight: 400;
}