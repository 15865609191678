.autosize-textarea{
  flex-direction: row;
  align-items: center;
  textarea{
    flex:1;
    resize: none;
    line-height: 18px;
    color: inherit;
    &:disabled{
      opacity: 0.3;
    }
    &::placeholder{
      color:inherit;
    }
  }
}