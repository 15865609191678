.report-feedback{
  padding: 16px 0;
  justify-content: center;
}

.report-feedback-title{
  margin-top:24px;
  font-weight: 600;
  text-align: center;
  font-size:16px;
  line-height: 24px;;
}
.report-feedback-icon{
  margin-top:24px;
  justify-content: center;
  align-items: center;
}
.report-feedback-text{
  margin-top:8px;
  text-align: center;
  color:#8e8e8e;
  padding: 0 16px;
}
.report-feedback-tags{
  margin-top:44px;
}