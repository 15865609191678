.spin {
  -webkit-animation: spinner 1.2s steps(12) infinite;
  animation: spinner 1.2s steps(12) infinite;
}
.thin-spin{
  -webkit-animation: spinnerLowRing .8s steps(8) infinite;
  animation: spinnerLowRing .8s steps(8) infinite;
}
.spin-box{
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-webkit-keyframes spinnerLowRing {
  0% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg)
  }

  to {
      -webkit-transform: rotate(540deg);
      transform: rotate(540deg)
  }
}

@keyframes spinnerLowRing {
  0% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg)
  }

  to {
      -webkit-transform: rotate(540deg);
      transform: rotate(540deg)
  }
}