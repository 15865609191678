.suggested-in-tray{
  padding:12px 0;
  background: #fafafa;
}
.in-tray-header{
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding:0 20px 12px 20px;

}
.tray-header-title{
  color: #8e8e8e;
  font-weight: 600;
}
.tray-header-more{
  color: #0095f6;
  font-weight: 600;
}

@media (min-width: 640px) {
  div.suggested-users-tray{
    border: 1px solid #dbdbdb;
    padding:20px 0;
    margin-bottom:60px;
    border-radius: 4px;
  }
}