.carousel{
  overflow: hidden;
  touch-action: pan-y;
}

.carousel-content{
  display: flex;
  flex-direction: row;
  will-change:transfrom;
  direction: ltr;
  width:100%;
}
.carousel-item{
  position: relative;
  flex:0 0 auto;
}
button.carousel-btn-left,button.carousel-btn-right{
  padding: 16px 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.carousel-btn-left{
  left:0;
}
.carousel-btn-right{
  right:0;
}

.carousel-dots{
  flex-direction: row;
  justify-content: center;
  padding-bottom: 15px;
  padding-top: 15px;
  position:absolute;
  bottom:-36px;
  width:100%;
}
.carousel-dot{
  margin-right: 4px;
  background-color:#a8a8a8;
  transition: all .2s ease-in-out;
  width:6px;
  height:6px;
  border-radius: 4px;
}
.carousel-dot-active{
  background-color:#0095f6;
}