.list-modal {
  position: relative;
  width:100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
}

.list-modal-content {
  width: calc(100% - 88px);
  background-color: #ffffff;
  border-radius: 12px;
  max-height: calc(100% - 40px);
  pointer-events: auto;
  overflow-x: auto;
  .list-modal-item:first-child {
    border-top: none;
  }

  button:not(.not-for-btn) {
    &:active {
      -webkit-tap-highlight-color: transparent;
      background-color: rgba(0, 0, 0, .1);
      opacity: 1;
    }
  }
  @media (min-width: 736px) {
    width: 400px;
  }
}


button.list-modal-item {
  line-height: 1.5;
  padding: 4px 8px;
  text-align: center;
  vertical-align: middle;
  min-height: 48px;
  border-top: 1px solid #dbdbdb;
  cursor: pointer;
  font-weight: 400;
}

.not-for-btn {
  padding: 0;
}

button.modal-item-WARNING {
  color: #ed4956;
  font-weight: 700;
}
button.modal-item-PRIMARY {
  color: #0095f6;
  font-weight: 700;
}
