.setting{
  padding-top:30px;
  &:not(:last-child){
    border-bottom: 1px solid #dbdbdb;
  }
}

.setting-title{
  font-size: 22px;
  line-height: 26px;
  margin: -4px 0 -5px;
  padding-bottom:24px;
}

.setting-hint{
  font-size: 14px;
  line-height: 18px;
  margin: -3px 0 -4px;
  font-weight: 400;
  padding-top:16px;
  padding-bottom:24px;
  color:#888888;
}
