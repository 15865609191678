.suggested-user{
  align-items: center;
  padding:0 2.5px;
  width:161px
}
.suggested-user-content{
  width:156px;
  background:#fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding:16px;
  align-items: center;
}

button.user-content-close{
  cursor: pointer;
  padding: 12px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
div.user-content-description{
  min-height: 28px;
  word-break: break-all;
  margin-bottom:4px;
}
.user-content-username{
  margin-bottom:-2px;
  height: 28px;
  justify-content: center;
}


@media (min-width:640px) {
  .suggested-user{
    width:200px;
  }
  .suggested-user-content{
    width: 176px;
    padding: 20px;
  }
}