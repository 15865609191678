.feed{
  touch-action: manipulation;
}

.feed-user{
  padding:12px;
}

.feed-poster-icon{
  align-items: flex-end;
  position:absolute;
  top:0;
  left:0;
  pointer-events: none;
  bottom:0;
  right:0;
  justify-content: flex-start;
}

.feed-comment{
  flex-direction: row;
  padding:12px;
  overflow-wrap: break-word;
  display: block;
}

.comment-text-btn{
  padding:0;
  display:inline;
}