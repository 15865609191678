
.badge{
  padding: 0 3px;
  align-items: center;
  border-radius: 100px;
  color: #fff;
  font-size: 11px;
  height: 18px;
  justify-content: center;
  min-width: 18px;
}