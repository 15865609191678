.message-liked{
  background-color: #efefef;
  border: 2px solid #fff;
  border-radius: 20px;
  flex-direction: row;
  padding: 6px;
  width: max-content;
  bottom: 4px;
  position: absolute;
  z-index: 1;
}

.message-liked-btn{
  padding:0;
  flex-direction: row;
}

.liked-btn-heart{
  font-size:12px;
  width:14px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  color:#0095f6;
}

.liked-btn-user{
  flex-direction: row;
  margin-left:4px;
}
.btn-user-avatar{
  margin-right:4px;
}

.message-liked-user{
  padding:8px 16px;
  flex-direction: row;
  align-items: center;
}

.liked-user-heart{
  margin-left:8px;
}