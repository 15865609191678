.create-style{
  padding-bottom:100%;
  border-bottom: 1px solid #dbdbdb;
  touch-action: none;
}

.create-style-content{
  background-color: #fff;
  background-repeat: no-repeat;
  bottom: 0;
  display: block;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}


.style-content-img{
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  will-change: height,left,top,width,transform;
  user-select: none;
}

.content-img-expand,.content-img-rotate{
  background-color: transparent;
  border: 0;
  bottom: 16px;
  outline: 0;
  padding: 0;
  position: absolute;
  z-index: 2;
}

.content-img-expand{
  left:16px;
}

.content-img-rotate{
  right:16px;
}