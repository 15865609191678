.MODALLIKE-scale-in {
  animation: ModalScaleIn .1s ease-out;
}

.TOASTLIKE-scale-in{
  animation: toastScaleIn 250ms ease-out 
}

@-webkit-keyframes ModalScaleIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes ModalScaleIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}


@-webkit-keyframes toastScaleIn {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.5);
    transform: translate(-50%, -50%) scale(1.5)
  }

  to {
    opacity: .9;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
  }
}

@keyframes toastScaleIn {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.5);
    transform: translate(-50%, -50%) scale(1.5)
  }

  to {
    opacity: .9;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
  }
}