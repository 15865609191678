.notification{
  position:absolute;
  bottom:60px;
  left:50%;
  transform: translate(-50%);
  transform-origin: bottom center;
  background-color: #ed4956;
  color:#fff;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 20%) 0 4px 22px;
  padding: 8px 12px;
  user-select: none;
  transition: opacity .3s cubic-bezier(.175,.885,.32,1.275),transform .3s cubic-bezier(.175,.885,.32,1.275);
}
.notification-arrow{
  left: 0;
  position: absolute;
  width: 100%;
  bottom:-6px;
  height: 15px;
  &:after{
    content:'';
    position:absolute;
    bottom:0;
    left:50%;
    border-radius: 2px;
    height: 15px;
    transform: translateX(-50%) rotate(45deg);
    width: 15px;
    background-color: #ed4956;
  }
}

.notification-content{
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  color:#fff;
}
.notification-hide{
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translate(-50%) scale(.5);
  transform: translate(-50%) scale(.5);
}