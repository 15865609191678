.tabs{
  flex-direction: row; 
}

.tab-bar{
  flex:1;
  font-weight: 400;
  color:#8e8e8e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}

.tab-panel{
  flex:1;
  overflow: auto;
}

.tab-panel-hide{
  display: none;
}

.bar-TOP-active{
  font-weight: 600;
  border-top: 1px solid #262626;
  margin-top:-1px;
  color:#262626;
}
.bar-BOTTOM-active{
  font-weight: 600;
  border-bottom: 1px solid #262626;
  margin-bottom:-1px;
  color:#262626; 
}

.tabs-border-TOP{
  border-top: 1px solid rgba(0,0,0,.0975);
}

.tabs-border-BOTTOM{
  border-bottom: 1px solid rgba(0,0,0,.0975);
}