.label-input{
  flex-direction: row;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 8px;
  align-items: center;
  background: #fafafa;
  box-sizing:content-box;
}

.label-input-focus{
  border-color:#888888;
}
.label-input-error{
  border-color:#ed4956;
}

.label-input-error{
  color:#ed4956;
  font-size: 12px;
}
.label-input-help{
  font-size: 12px;
  color:#8e8e8e;
  margin-bottom:12px;
  text-align: center;
}

.label-input-content{
  flex:1;
  display:flex;
  height:38px;
}

.input-content-placeholder{
  color: #8e8e8e;
  font-size: 12px;
  left: 8px;
  line-height: 38px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-overflow: ellipsis;
  transform-origin: left;
  transition: transform ease-out .1s;
  user-select: none;
  white-space: nowrap;
}
.input-content-inner{
  border: 0;
  flex: 1 0 auto;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 9px 0 7px 8px;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.content-placeholder-active{
  transform: scale(.83333) translateY(-10px);
}

.content-inner-active{
  font-size: 12px;
  padding: 14px 0 2px 8px;
}