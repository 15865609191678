.creation-filter-thumbnail {
  padding: 0 3px;
  align-items: center;
  flex-direction: column;
}

.filter-thumbnail-title {
  font-size: 12px;
  color: #8e8e8e;
  line-height: 14px;
  padding-bottom: 10px;
  text-align: center;
}

.thumbnail-title-selected{
  font-weight: 600;
  color:#262626;
}

.filter-thumbnail-img{
  background-color: #efefef;
  height: 100px;
  transition: transform .05s ease-out;
  width: 100px;
  &:active{
    transform: scale(.95);
  }
}