.photo-create{
  flex:1;
  flex-direction: column-reverse;
}
.photo-create-btn{
  height: 44px;
}
.photo-create-mask{
  background: radial-gradient(circle at center,transparent 0,transparent 69.9%,#000 70%,#000 100%);
  opacity: .5;
  position:absolute;
  top:0;
  left:0;
  right:0;
  padding-bottom: 100%;
  pointer-events: none;
  z-index: 1; 
}

.photo-create-tab{
  overflow: auto;
}