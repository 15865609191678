.video-player{
  position: relative;
  width:100%;
  height:100%;
}
.video{
  width:100%;
  height:100%;
}
.video-player-status,.video-player-mask{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  align-items: center;
  justify-content: center;
}
.player-status-play{
  padding:0;
  opacity: 1;
  transition: opacity .2s ease-out;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}