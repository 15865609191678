.message-container{
  flex: 1 1 auto;
  min-height: 0;
  min-width: 0;
  height:100%;
  width:100%;
  justify-content: flex-end;
  align-content: stretch;
}
.message-container-content{
  padding: 16px 16px 0;
  flex:0 1 auto;
}

.container-content-readed{
  font-size: 12px;
  line-height: 14px;
  margin: -2px 0 5px;
  font-weight: 400;
  color: #8e8e8e;
  align-items: flex-end;
}