.search-input{
  flex: 1;
}
.search-input-label {
  display: flex;
  position: relative;

  input {
    appearance: none;
    background-color: #fff;
    line-height: 20px;
    border-radius: 6px;
    border: 1px solid #dbdbdb;
    color: #262626;
    flex-grow: 1;
    font-size: 14px;
    margin: 0;
    overflow: visible;
    padding: 4px 20px 4px 22px;

    &:focus {
      border: 1px solid #a8a8a8;
    }

    &::-webkit-search-cancel-button {
      appearance: none;
    }
  }
}

.input-label-placeholder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  padding: 0 8px;
  pointer-events: none;
  max-width: 100%;
  transform: translateX(-50%);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: left .15s ease-out, transform .15s ease-out, -webkit-transform .15s ease-out;
}

.label-placeholder-active {
  left: 0;
  transform: translateX(0);
}

.label-placeholder-icon {
  flex: 0;
}

.label-placeholder-text {
  color: #8e8e8e;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-label-actions{
  top: 0;
  right: 0;
  position: absolute;
  bottom: 0;
  margin-right: 4px;
  align-items: center;
  justify-content: center;
}