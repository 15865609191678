.direct-search-user{
  flex:1;
  height: 0;
  overflow: auto;
}
.search-user-input{
  flex-direction: row;
  align-items: center;
  padding:8px 0;
  border-bottom:1px solid #dbdbdb;
  overflow-x: scroll;
}

.user-input-title{
  font-weight: 600;
  width:65px;
  padding:4px 60px 4px 12px;
  font-size:16px;
  line-height: 24px;
  box-sizing: border-box;
  white-space: pre
}

.user-input-content,.input-content-tags{
  flex-direction: row;
}


.input-content-inner{
  flex:1;
  border-radius: 6px;
  font-size: 14px;
  line-height: 30px;
  padding: 4px 12px;
}

.suggested{
  flex:1;
}

.suggested-title{
  font-weight: 600;
  padding:16px;
}
.users-with-radio{
  flex:1;
  height: 0;
  overflow: auto;
}

.with-radio-btn{
  padding:8px 16px;
  justify-content: flex-start;
  text-align: left;
}