.loading-bar {
  position: fixed;
  z-index: 10;
  top: 0;
  left:0;
  right:0;
  background: #27c4f5 -webkit-gradient(linear, left top, right top, from(#27c4f5), color-stop(#a307ba), color-stop(#fd8d32), color-stop(#58c322), to(#27c4f5));
  background: #27c4f5 -webkit-linear-gradient(left, #27c4f5, #a307ba, #fd8d32, #58c322, #27c4f5);
  background: #27c4f5 linear-gradient(to right, #27c4f5, #a307ba, #fd8d32, #58c322, #27c4f5);
  background-size: 500%;
  -webkit-animation: LoadingBarProgress 2s linear infinite ,LoadingBarEnter .5s ease-out ;
  animation: LoadingBarProgress 2s linear infinite ,LoadingBarEnter .5s ease-out ;
  -webkit-transform-origin: left;
  transform-origin: left;
  width: 100%;
}

@-webkit-keyframes LoadingBarProgress {
  0% {
    background-position: 0% 0;
  }

  to {
    background-position: 125% 0;
  }
}

@keyframes LoadingBarProgress {
  0% {
    background-position: 0% 0;
  }

  to {
    background-position: 125% 0;
  }
}

@-webkit-keyframes LoadingBarEnter {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

@keyframes LoadingBarEnter {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}