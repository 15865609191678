.birth-date-placehoder{
  margin-top:12px;
  margin-bottom:8px;
  color:#8e8e8e;
  text-align: center;
  line-height: 14px;
  font-size:12px;
}

.birth-date-content{
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.date-conent-select{
  margin-right:8px;
}