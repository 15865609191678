.search-result{
  max-height: 200px;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}

.search-result-user{
  padding: 10px 16px;
  height:50px;
  border-bottom: solid 1px #dbdbdb;
}
.tag{
  height: 50px;
  padding: 10px 16px;
  flex-direction: column;

  white-space: nowrap;
  align-items:flex-start;
  border-bottom: solid 1px #dbdbdb;
}

.tag-name{
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag-post-count{
  color: #8e8e8e;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
}