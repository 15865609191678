.img {
  height: 100%;
  -webkit-touch-callout: none;
  width: 100%;
}

img[src=""] {
  opacity: 0;
}

.img-spin{
  position:absolute;
  top:0;
  width:100%;
  height:100%;
  z-index: 1;
  pointer-events: none;
}