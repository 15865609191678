.tag-button{
  flex-direction: row;
  align-items: center;
  font-weight: 400;
}

.tag-button{
  height:35px;
  padding:0 12px;
  margin:4px;
  background-color: #e0f1ff;
  color: #0095f6;
}

.tag-buttn-closable{
  background-color: #0095f6;
  color:#fff;
}