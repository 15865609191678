.in-username-dot{
  padding:0 4px;
}
.follow-in-username{
  flex-direction:row;
  align-items: center;
  margin-left:8px;
}
.in-username-btn{
  padding:0;
  color:#0095f6;
}