.composer{
  padding:16px;
}
.composer-content{
  border-radius:22px ;
  border:1px solid #dbdbdb;
  padding-left: 11px;
  padding-right: 8px;
  min-height: 44px;
  textarea{
    padding: 8px 9px;
  }
}

.composer-btn{
  margin-right:8px;
  padding:0;
  color:#0095f6;
}

.composer-tool{
  padding:8px;
}