.input{
  background: 0 0;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  box-sizing: border-box;
  color: #262626;
  font-size: 16px;
  height: 32px;
  padding: 0 10px;
  width:100%;
  -webkit-appearance: none;
  &:focus{
    border: 1px solid #a8a8a8;
  }
}

.textarea{
  background: 0 0;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  box-sizing: border-box;
  color: #262626;
  width:100%;
  font-size: 16px;
  height: 60px;
  padding: 6px 10px;
  resize: vertical;
  &:focus{
    border: 1px solid #a8a8a8;
  } 
}