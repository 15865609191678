.cube-effect{
  width:100%;
  height: 100%;
  perspective: 1200px;
  //transform-origin:left top 200px;
  touch-action: pan-y;
}
.cube-effect-content {
  flex-direction: row;
  transition-property: transform;
  transition-timing-function: ease-in-out;
  transform-style: preserve-3d;
  transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(-90deg);
  width:100%;
  height: 100%;
}

.effect-content-item {
  flex:0 0 auto;
  width:100%;
  height: 100%;
  transform-origin: 0 0;
  z-index: 1;
  backface-visibility: hidden;
}

.prev {
  transform: rotateX(0deg) rotateY(0deg) translate3d(0px, 0px, 0px);
}
.current{
  transform: rotateX(0deg) rotateY(90deg) translate3d(0px, 0px, 0px);
}
