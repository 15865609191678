.restrict-confirm{
  padding:16px 24px;
}
.restrict-confirm-title{
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  padding:0 16px 16px 16px;
}

.restrict-confirm-description{
  flex-direction: row;
}

.confirm-description-icon{
  margin-top:12px;
}

.confirm-description-text{
  flex: 1;
  color:#8e8e8e;
  padding-bottom: 12px;
  padding-top: 12px;
  margin-left:16px;
}

.restrict-confirm-btn{
  padding:20px 0;
  margin-top:16px;
  margin-bottom:20px;
  color:#0095f6;
}