.feed-like {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;
  pointer-events: none;
  justify-content: center;
  align-items: center;
}

.feed-like-heart{
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-name: like-heart-animation;
  animation-name: like-heart-animation;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-transform: scale(0);
  transform: scale(0);
}

@-webkit-keyframes like-heart-animation {

  0%,
  to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  15% {
    opacity: .9;
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }

  30% {
    -webkit-transform: scale(.95);
    transform: scale(.95)
  }

  45%,
  80% {
    opacity: .9;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes like-heart-animation {

  0%,
  to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0)
  }

  15% {
    opacity: .9;
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }

  30% {
    -webkit-transform: scale(.95);
    transform: scale(.95)
  }

  45%,
  80% {
    opacity: .9;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}