.post-comment {
  padding: 0 16px;
}

.post-comment-like {
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 8px;
  align-items: flex-start;
  a{
    flex-direction: row;
    color: inherit;
  }
}

.like-action-first{
  flex-direction: row;
  align-items: center;
}
.action-first-btn{
  padding:0;
}
.comment-like-action{
  margin-bottom:8px;
}

.like-action-user{
  flex-direction: row;
  align-items: center;
  a{
    font-weight: 600;
    color:inherit;
  }
}

.post-comment-root {
  display: block;
  margin-bottom: 4px;
}

.comment-root-text {
  word-break: break-word;
}

.post-comment-num {
  margin-bottom: 4px;
  a {
    color: #8e8e8e;
  }
}

.comment-recent-item {
  align-items: center;
  margin-bottom: 4px;
  flex-direction: row;
}

.recent-item-content {
  display: block;
  flex: 1;
}
.post-comment-recent{
  padding-bottom:4px;
}

.item-content-text {
  overflow-wrap: break-word;
  line-height: 1;
  word-break: break-all;
}
.post-comment-time{
  margin-top:4px;
  margin-bottom:4px;
  align-items: flex-start;
  time{
    font-weight: 400;
  }
}