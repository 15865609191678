.guide-to-app{
  padding:8px 16px;
}
.to-app-logo{
  justify-content: center;
  align-items: center;
  margin-bottom:12px;
}
.to-app-title{
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: -6px 0 -6px;
  text-align: center;
  padding-bottom: 12px;
}
.to-app-text{
  color:#8e8e8e;
  font-weight: 400;
  margin: -3px 0 -4px;
  padding-bottom:16px;
  text-align: center;
}