
.avatar{
  display: block;
  border-radius: 50%;
  flex: 0 0 auto;
  background-color:#fafafa;
  position: relative;
  //overflow: hidden;
  &::after{
    content:'';
    border:1px solid rgba(0,0,0,0.0975);
    border-radius: 50%;
    bottom:0;
    position:absolute;
    left:0;
    top:0;
    right: 0;
  }
}

.avatar-group{
  flex-direction: row;
  align-items: center;
}

.avatar-group-item{
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}


.item-border-WHITE{
  border-color:#fff;
}

.item-border-BLACK{
  border-color:#000;
}

.item-border-TRANSPARENT{
  border-color:transparent;
}

.group-item-LEFT{
  margin-left: -9px;
}

.group-item-RIGHT{
  margin-right: -9px;
}


@media (-webkit-min-device-pixel-ratio: 2),(min-resolution:2dppx) {
  .avatar-group-item{
    border:0;
    &::before{
      border: 5px solid #fff;
      border-radius: 50%;
      content: '';
      height: 200%;
      left: -2.5px;
      position: absolute;
      top: -2.5px;
      -webkit-transform: scale(.5);
      transform: scale(.5);
      -webkit-transform-origin: top left;
      transform-origin: top left;
      width: 200%;
    }
  }
  
  .item-border-WHITE{
    &::before{
      border-color:#fff;
    }
  }
  
  .item-border-BLACK{
    &::before{
      border-color:#000;
    }
  }
  
  .item-border-TRANSPARENT{
    &::before{
      border-color:transparent;
    }
  }
  
  .group-item-LEFT{
    margin-left: -5px;
  }
  
  .group-item-RIGHT{
    margin-right: -5px;
  }
}