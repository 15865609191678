.post-tags{
  position:absolute;
  top:0;
  left:0;
  width: 100%;
  height:100%;
  z-index: 2;
}
.post-tags-content{
  height:100%;
}
.tags-content-icon{
  bottom: 0;
  left: 0;
  position: absolute;
  background: rgba(0,0,0,.8);
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  margin: 12px;
  padding: 8px;
  opacity: 0;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.tags-account-active {
  .tags-content-account{
    pointer-events: auto;
    opacity: 1;
  }
}

.content-icon-active{
  opacity: 1;
}

.tags-content-account{
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition-duration: .2s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.16,1.275,.725,1.255);
}

.none-pointer{
  pointer-events: none;
}

.auto-pointer{
  pointer-events: auto;
}