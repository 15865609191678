.message{
  flex:0 0 auto;
}

.message-content{
  flex-direction: row;
}


.message-content-avatar{
  margin-right:8px;
  width:24px;
}

.LEFT{
  align-items: flex-end;
  div[role="TEXT"],div[role="FEED"],div[role="USER"]{
    border: 1px solid #efefef;
  }
  div[role="LIKEDUSERS"]{
    left:0;
  }
}

.RIGHT{
  flex-direction: row-reverse;
  div[role="TEXT"],div[role="FEED"],div[role="USER"]{
    background-color: #efefef;
    border: 1px solid #efefef;
  }
  div[role="LIKEDUSERS"]{
    right:0;
  }
}

.CENTER{
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.message-content-typing{
  align-items: center;
}


.message-time{
  text-align: center;
  margin-top:12px;
  margin-bottom:20px;
}

.message-time-text{
  margin: -2px 0 -3px;
}