
.app-banner{
  flex:1;
  margin-top:5px;
  justify-content: center;
  align-items: center;
}

.badge{
  position:absolute;
  top:-6px;
  right:-8px;
}