
.share-message-btn{
  padding:0 16px 16px 16px;
}

.share-message-input{
  height: 64px;
  padding-bottom: 7px;
  padding-top: 7px;
  visibility: visible;
  transition: height 200ms,padding 200ms;
  border-top:1px solid #dbdbdb;
  input{
    height: 42px;
    line-height: 30px;
    padding: 4px 12px 4px 20px;
    flex-grow: 1;
    font-size: 14px;
  }
}

.share-message-hide{
  height:0px;
  padding-top:0;
  padding-bottom:20px;
  overflow: hidden;
}