.view-reply{
  margin-bottom:16px;
  flex-direction: row;
  align-content: center;
}
.view-reply-expand{
  display: flex;
  flex-direction: row;
  align-content: center;
  padding:0;
}
.reply-expand-line{
  border-bottom: 1px solid #8e8e8e;
  height: 0;
  margin-right: 16px;
  width: 24px;
}

.reply-expand-text{
  color: #8e8e8e;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}