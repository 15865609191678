.time {
  background: 0 0;
  border: 0;
  cursor: pointer;
  display: inline;
  font-size: 12px;
  line-height: 14px;
  padding: 0;
  color: #8e8e8e;
}
