
.share-item{
  height: 48px;
  padding:8px 16px;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  span{
    display: block;
  }
  &:hover{
    background-color: #fafafa;
  }
}
.share{
  .share-item:last-child{
    padding-left:56px;
  }
}