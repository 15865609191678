.user-skeleton{
  align-items: center;
}
.user-skeleton-row{
  flex-direction: row;
}

.user-skeleton-column{
  flex-direction: column;
}

.user-skeleton-avatar{
  background:#efefef;
  border-radius: 50%;

}

.user-skeleton-description{
  justify-content: space-between;
  padding:4px 0;
  height: 100%;
}
.skeleton-description-top,.skeleton-description-bottom{
  background:#efefef;
}