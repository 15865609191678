.like-button {
  animation-duration: .45s;
  animation-name: like-button-animation;
  animation-timing-function: ease-in-out;
  transform: scale(1);
}

@-webkit-keyframes like-button-animation {

  0%,
  to {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  25% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }

  50% {
    -webkit-transform: scale(.95);
    transform: scale(.95)
  }
}

@keyframes like-button-animation {

  0%,
  to {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  25% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }

  50% {
    -webkit-transform: scale(.95);
    transform: scale(.95)
  }
}