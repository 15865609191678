.release-comment{
  margin-top: 4px;
  padding: 0 16px;
  min-height: 56px;
  border-top: 1px solid #efefef;
  justify-content: center;
  justify-content: center;
}
.release-comment-focus{
  margin: 20px -20px 0;
}