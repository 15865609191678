.comment{
  flex-direction: row;
  align-items: flex-start;
}
.comment-with-like{
  justify-content: space-between;
  .comment-contianer{
    width:calc(100% - 28px);
  }
}
.comment-contianer{
  flex-direction: row;
  width:100%;
}
.comment-contianer-content{
  display: inline-block;
  word-break: break-word;
  flex:1;
  min-width: 0;
}

.contianer-content-actions{
  flex-direction: row;
  align-items: center;
  padding-top:16px;
  padding-bottom:4px;
  font-size: 12px;
  line-height: 14px;
  margin: -2px 0 -3px;
  time{ 
    font-weight: 400;
  }
}

.contianer-content-username{
  display:inline-flex;
  font-weight: 600;
  margin-right: 4px;
}
.content-actions-reply{
  color:#8e8e8e;
  display:inline;
  padding:0;
  font-weight: 600;
}
.content-actions-like{
  font-weight: 600;
  margin-right: 12px;
  display: inline;
  color:#8e8e8e;
}