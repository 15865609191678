a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  color: #2a2a2a;
  font: inherit;
  font-size: 14px;
  line-height: 18px;
}

body {
  line-height: 1;
  font-family: apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
}
html{
  -webkit-text-size-adjust: 100%;
}
ol,ul {
  list-style: none
}

blockquote,q {
  quotes: none
}
#root, article, div, footer, header, main, nav, section,a{
  align-items: stretch;
  border: 0 solid #000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
}
#root,body,html{
  height: 100%;
}

blockquote:after,blockquote:before,q:after,q:before {
  content: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}
svg{
  display: block;
}
a{
  color: #00376b;
  text-decoration: none;
  &:visited{
    color: #00376b;
  }
}
button,textarea,input{
  margin:0;
  padding:0;
  background: none;
  border: none;
  outline: none;
  font: inherit;
  color: #262626;
}
