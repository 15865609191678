.post-actions{
  padding:0 16px;
  flex-direction: row;
  .post-actions-btn{
    &:first-child{
      margin-left:-8px;
    }
    &:last-child{
      margin-left:auto;
      margin-right:-10px;
    }
  }
}
.post-actions-btn{
  padding:8px;
}